import css from 'styled-jsx/css';

export const carouselStyles = css`
    .carousel-slider {
        background-color: red !important;
        top: 0;
        bottom: 0;
        padding: 0.3125rem;
    }

    .react-multi-carousel-track > li[aria-hidden='true'] {
        display: none !important;
    }

    .slider-wrapper {
        min-height: 240px;
    }

    .slider-wrapper.axis-horizontal {
        height: auto !important;
    }

    .slider.animated {
        height: auto !important;
    }

    .slider.animated.selected {
        opacity: 1;
    }

    .carousel .slider .slide {
        padding: 30px;
        text-align: center;
        background-color: inherit;
        transition: all 0.2s ease-in-out;
    }

    .control-dots {
        bottom: 0;
        margin: 0;
        text-align: center;
        padding: 0;
        width: 100%;
    }

    .control-dots li {
        box-shadow: none !important;
        background: #ccc !important;
    }

    .control-arrow {
        background: inherit;
        opacity: 0.2;
    }

    .featured-img {
        background-size: cover;
        height: 200px;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 200px;
        background-position: center center;
        flex-shrink: 0;
        padding: 0;
    }
`;
