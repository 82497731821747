import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Link from 'next/link';
import { carouselStyles } from './carouselStyles';
import styles from './Slider.module.sass';
import Image from 'next/image';
import cx from 'classnames';
import noLinkHash from '@/utils/noLinkHash';
import Background from '@/components/Background';
import Container from '@/components/Container';
import SpacingLayout from '@/components/SpacingLayout';
import SubCTA from '@/components/SubCTA';
import SubHeader from '@/components/SubHeader';
import LinkContainer from '@/components/SubCTA/LinkContainer';

const Slider = props => {
    const { sliderOptions, subHeader, subLayout, subBackground, subcta } = props;
    const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta;
    const { customizeContent, postsAndPages, sliderLayout, boxShadow, imageSize } = sliderOptions;
    const sliding = postsAndPages?.length > 1 || sliderLayout?.length > 1;
    const sliderImageSize = imageSize?.split('x');
    const imageWidth = sliderImageSize ? sliderImageSize?.[0] : '257';
    const imageHeight = sliderImageSize ? sliderImageSize?.[1] : '257';

    let content = postsAndPages && !customizeContent ? postsAndPages : sliderLayout || [];
    let uri;

    const ButtonGroup = ({ next, previous }) => {
        if (!sliding) return null;
        return (
            <div className={`${styles.carouselButtonGroup} pointer-events-none absolute top-0 flex h-full w-full flex-row justify-between`}>
                <button
                    aria-label="Go to previous slide"
                    className={`${styles.arrowLeft && styles.arrowLeft} relative left-[-1%] max-h-[575px] md:left-[20px]`}
                    onClick={() => previous()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button
                    aria-label="Go to next slide"
                    className={`${styles.arrowRight && styles.arrowRight} relative right-[-1%] max-h-[575px] md:right-[52px]`}
                    onClick={() => next()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        );
    };

    const CustomDot = ({ index, onClick, active, children }) => {
        return (
            <li>
                <button
                    key={index}
                    onClick={e => {
                        onClick();
                        e.preventDefault();
                    }}
                    aria-label="Go to slide"
                    className={cx('custom-dot mx-1 h-2 w-2 rounded-full bg-gray', !active && 'opacity-30')}>
                    {children}
                </button>
            </li>
        );
    };

    return (
        <Background {...subBackground}>
            <Container width={subLayout?.contentWidth} customWidth={subLayout?.customWidth}>
                <SpacingLayout defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist} boxShadow={boxShadow}>
                    <SubHeader {...subHeader} />
                    <div className="relative">
                        <Carousel
                            sliderClass={`${styles.carouselSlideContainer} react-slider h-full`}
                            swipeable={sliding}
                            draggable={sliding}
                            deviceType={'allScreens'}
                            showDots={sliding}
                            customDot={<CustomDot />}
                            infinite={true}
                            // autoPlaySpeed={10000}
                            pauseOnHover
                            keyBoardControl={true}
                            containerClass={`${styles.carouselContainer}`}
                            arrows={false}
                            ssr={true}
                            customButtonGroup={<ButtonGroup />}
                            customTransition={'transform 800ms ease-in-out'}
                            transitionDuration={800}
                            renderButtonGroupOutside={true}
                            renderDotsOutside={true}
                            dotListClass="relative top-0"
                            autoPlay={false}
                            centerMode={false}
                            partialVisible={true}
                            itemClass="m-0 flex justify-center p-4 sm:p-8"
                            slidesToSlide={1}
                            responsive={{
                                allScreens: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 0
                                    },
                                    items: 1
                                }
                            }}>
                            {content.map((c, i) => {
                                if (!c) return null;
                                const { featuredImage, image, imageLink, title, text, excerpt, pageHeaderSettings } = c;
                                const author = pageHeaderSettings?.postSettings?.overwriteAuthorName;
                                const contentExcerpt = `${excerpt?.substring(0, 300)}`;
                                const defaultImageUri = `/images/image-placeholder.png`;
                                const featuredImageUri = customizeContent ? image?.sourceUrl : featuredImage?.node?.sourceUrl || defaultImageUri;
                                uri = customizeContent ? imageLink?.url : c?.uri;
                                const imageClass = cx(styles.featuredImg, 'rounded-full object-cover aspect-square');
                                const imageComponent = (
                                    <Image key={i} className={imageClass} alt={image?.altText || title} src={featuredImageUri} width={imageWidth} height={imageHeight} />
                                );

                                return (
                                    <div key={`slider-` + i} className={`slider-item-container flex flex-col items-center sm:mx-8 sm:px-4 md:flex-row`}>
                                        <div className="slider-image-container flex items-center justify-center sm:w-full md:w-2/5">
                                            {uri ? (
                                                <Link aria-label={title} title={title} href={uri} as={uri} scroll={noLinkHash(uri)}>
                                                    {[imageComponent]}
                                                </Link>
                                            ) : (
                                                [imageComponent]
                                            )}
                                        </div>

                                        <div
                                            className={cx([
                                                'slider-content-container flex items-center pt-10 sm:w-full md:ml-2  md:pt-0 lg:mr-24',
                                                featuredImageUri && 'md:w-4/5 lg:w-3/5'
                                            ])}>
                                            {!customizeContent ? (
                                                <div>
                                                    <div
                                                        className="slider-desc content-container text-center leading-7 md:text-left"
                                                        dangerouslySetInnerHTML={{
                                                            __html: contentExcerpt
                                                        }}
                                                    />
                                                    <Link
                                                        title={`Read ${author}'s story - Jews for Jesus`}
                                                        hrefLang="en"
                                                        className={`content-container mt-3 block text-center md:text-left`}
                                                        href={uri}
                                                        as={uri}
                                                        scroll={noLinkHash(uri)}>
                                                        Read {author}
                                                        &apos;s story
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="slider-desc content-container text-center leading-normal md:text-left">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: text
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                    {callToAction?.length > 0 && (
                        <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                            {callToAction?.map((singleCta, index) => {
                                return (
                                    <SubCTA
                                        key={index}
                                        type={singleCta?.ctaDisplay}
                                        href={singleCta?.ctaLink?.url}
                                        target={singleCta?.ctaTarget}
                                        linkTarget={singleCta?.ctaLink?.target}
                                        text={singleCta?.ctaLink?.title}
                                        bgColor={singleCta?.ctaBgColor}
                                        arrowSize="large"
                                        textColor={singleCta?.ctaTextColor || 'white'}
                                        className={singleCta?.ctaCssClassesCssSafelist}
                                        modalContents={singleCta?.modalContents}
                                        darkStyles={Boolean(!subBackground?.backgroundUrl && singleCta?.ctaBgColor !== 'yellow')}
                                    />
                                );
                            })}
                        </LinkContainer>
                    )}
                    <style jsx>{carouselStyles}</style>
                </SpacingLayout>
            </Container>
            <style>
                {`
                    .react-slider li.react-multi-carousel-item div.slider-item-container {
                        transition-property: all;
                        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                        transition-duration: 500ms;
                    }                   
                    .react-slider li.react-multi-carousel-item:not(.react-multi-carousel-item--active) div.slider-item-container {
                        opacity: 0;
                        visibility: hidden;
                    }
                    .react-slider li.react-multi-carousel-item.react-multi-carousel-item--active div.slider-item-container{
                        opacity: 1;
                        visibility: visible;
                    }
                    `}
            </style>
        </Background>
    );
};

export default Slider;
